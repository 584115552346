import React from "react";
import AboutUs from "../components/about/aboutUs";
import Layout from "../components/layout/index";
import Seo from "../components/seo";

const index = () => {
  return (
    <Layout>
      <Seo
        slug="/about-us/"
        title="About US - Teenyfy"
        description="Teenyfy is a leading online link managment platfrom and tool offering link generator and short url. Track short URL traffic and manage your links. "
      />
      <AboutUs />
    </Layout>
  );
};

export default index;
