import React from "react";

const AboutUs = () => {
  return (
    <div className="container py-40 mx-auto">
      <h1 className="text-center font-[400] text-[40px] leading-[46px] text-[#1F1F39] font-open-sans pb-[50px]">
        Why Teenyfy
      </h1>
      <p className="text-center font-[400] text-[16px] leading-[24px] text-[#1F1F39] font-open-sans ">
        Teenyfy is a full-featured URL shortener service that can be used to not
        only turn long URLs into short ones, but also track the traffic coming
        from your links. Its dashboard shows trending links and general
        statistics, while an analytics page lets you dive into traffic by
        device, location, and referrers. You can also drill down into clicks by
        the time of day. Tags, which you can add to your shortened links, let
        you view your link traffic in new and custom ways and are perfect for
        tracking and coordinating campaigns.
      </p>
    </div>
  );
};

export default AboutUs;
